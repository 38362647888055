<template>
  <b-toast id="livetiming-progress" style="width: 300px;" :title="translateProgress('Fortschritt')" static no-auto-hide>
    <vue-slider
        ref="slider"
        v-model="value"
        v-bind="sliderOptions"
    ></vue-slider>
    <h1>{{ value }}</h1>
  </b-toast>
</template>

<script>
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: "LiveTimingProgressChoice.vue",
  mixins: [translationMixin, timingTranslationMixin],
  components: {
    VueSlider
  },
  data() {
    return {
      value: 0,
      sliderOptions: {
        max: 15000,
      },
      setMaxTimesTimeout : null,
    }
  },
  methods: {
    async getEventSettings() {
      const backend = this.$store.state.backend;
      let result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/admin/race/getEventSettings');
      if (result.status === 'ok') {
        if (result.data.status === 'ok') {
          this.value = parseInt(result.data.settings["ClassificationMaxTimesCount"]);
        }
      }

      result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/livetiming/getTimesCount');
      if (result.status === 'ok') {
        if (result.data.status === 'ok') {
          this.sliderOptions.max = parseInt(result.data.data);
        }
      }
    },

    async setClassificationMaxTimesCount(value) {
      const backend = this.$store.state.backend;
      const result =  await backend.post(process.env.VUE_APP_BACKEND_URL + '/admin/race/setClassificationMaxTimesCount', {value : value});
      if (result.status === 'ok')
      {
        if (result.data.status !== 'ok')
          this.$notifications.error(result.data.message);
      }
      else
        this.$notifications.error(result.message);
    }
  },
  mounted() {
    this.getEventSettings();
  },
  watch: {
    value: {
      handler(val) {
        if (this.setMaxTimesTimeout) {
          clearTimeout(this.setMaxTimesTimeout);
          this.setMaxTimesTimeout = null;
        }
        this.setMaxTimesTimeout = setTimeout(() => this.setClassificationMaxTimesCount(this.value), 200);
      }
    }
  }
}
</script>

<style scoped>

</style>