import XRegExp from "xregexp";

export const timingTranslationMixin = {
  data() {
    return {
      translationClassificationTitles : {
        DEU : {
        },
        ENG : {
          "Gruppen" : "Groups",
          "Klassen" : "Classes",
          "Gesamt" : "Total",
          "Sektoren" : "Intermediates",
          "Geschwindigkeiten" : "Speeds",
        },
        FRA : {
          "Gruppen" : "Groupes",
          "Klassen" : "Classes",
          "Gesamt" : "Classement Scratch",
          "Sektoren" : "Secteurs",
          "Geschwindigkeiten" : "Vitesses",
        },
        ITA : {
          "Gruppen" : "Gruppi",
          "Klassen" : "Classi",
          "Gesamt" : "Classifica",
          "Sektoren" : "Settori",
          "Geschwindigkeiten" : "Velocità",
        },
        CZE : {
          "Gruppen" : "Skupiny",
          "Klassen" : "Třídy",
          "Gesamt" : "Výsledky",
          "Sektoren" : "Sektory",
          "Geschwindigkeiten" : "Rychlosti",
        },
        POL : {
          "Gruppen" : "Grupy",
          "Klassen" : "Klasy",
          "Gesamt" : "Ogólny Wynik",
          "Sektoren" : "Sektory",
          "Geschwindigkeiten" : "Prędkości",
        },
      },

      translationGroupTitles : {
        DEU : {
        },
        ENG : {
          "Gruppe" : "Group",
          "Gruppen" : "Groups",
          "Klasse" : "Class",
          "Klassen" : "Classes",
          "und" : "and",
          "über" : "over",
          "bis" : "to"
        },
        FRA : {
          "Gruppe" : "Groupe",
          "Gruppen" : "Groupes",
          "Klasse" : "Classe",
          "Klassen" : "Classes",
          "und" : "et",
          "über" : "plus que",
          "bis" : "jusqu'á"
        },
        ITA : {
          "Gruppe" : "Gruppo",
          "Gruppen" : "Gruppi",
          "Klasse" : "Classe",
          "Klassen" : "Classi",
          "und" : "e",
          "über" : "più di",
          "bis" : "a"
        },
        CZE : {
          "Gruppe" : "Skupina",
          "Gruppen" : "Skupiny",
          "Klasse" : "Třída",
          "Klassen" : "Třídy",
          "und" : "a",
          "über" : "nad",
          "bis" : "do"
        },
        POL : {
          "Gruppe" : "Grupa",
          "Gruppen" : "Grupy",
          "Klasse" : "Klasa",
          "Klassen" : "Klasy",
          "und" : "a",
          "über" : "nad",
          "bis" : "do"
        },
      },

      translationTableHeads : {
        DEU : {
        },
        ENG : {
          "Rg" : "Rk",
          "Rang" : "Rank",
          "Nr" : "No",
          "Klasse" : "Class",
          "Gruppe" : "Group",
          "Fahrer" : "Driver",
          "Bewerber" : "Competitor",
          "Fahrzeug" : "Vehicle",
          "Nat" : "Nat",
          "Lauf" : "Heat",
          "Sektoren" : "Intermediates",
          "Sektor" : "Sector",
          "Bester" : "Best",
          "Summe" : "Sum",
          "Gesamt" : "Total",
        },
        FRA : {
          "Rg" : "Rg",
          "Rang" : "Rang",
          "Nr" : "No",
          "Klasse" : "Classe",
          "Gruppe" : "Groupe",
          "Fahrer" : "Conducteur",
          "Bewerber" : "Concurrent",
          "Fahrzeug" : "Véhicule",
          "Nat" : "Nat",
          "Lauf" : "Course",
          "Sektoren" : "Secteurs",
          "Sektor" : "Secteur",
          "Bester" : "Meill.",
          "Summe" : "Total",
          "Gesamt" : "Total",
        },
        ITA : {
          "Rg" : "Pos",
          "Rang" : "Pos",
          "Nr" : "N",
          "Klasse" : "Classe",
          "Gruppe" : "Gruppo",
          "Fahrer" : "Conduttore",
          "Bewerber" : "Scuderia",
          "Fahrzeug" : "Vettura",
          "Nat" : "Naz",
          "Lauf" : "Gara",
          "Sektoren" : "Settori",
          "Sektor" : "Settore",
          "Bester" : "Migliore",
          "Summe" : "Totale",
          "Gesamt" : "Tempo",
        },
        CZE : {
          "Rg" : "Poř",
          "Rang" : "Poř",
          "Nr" : "S.č.",
          "Klasse" : "Třída",
          "Gruppe" : "Skupina",
          "Fahrer" : "Jezdec",
          "Bewerber" : "Závodník",
          "Fahrzeug" : "Vůz",
          "Nat" : "Nár",
          "Lauf" : "Běh",
          "Sektoren" : "Sektory",
          "Sektor" : "Sektor",
          "Bester" : "Nejlepší",
          "Summe" : "Celkový",
          "Gesamt" : "Celkový",
        },
        POL : {
          "Rg" : "Poz",
          "Rang" : "Poz",
          "Nr" : "Nr",
          "Klasse" : "Klasa",
          "Gruppe" : "Grupa",
          "Fahrer" : "Kierowca",
          "Bewerber" : "Konkurent",
          "Fahrzeug" : "Pojazd",
          "Nat" : "Nar",
          "Lauf" : "Bieg",
          "Sektoren" : "Sektory",
          "Sektor" : "Sektor",
          "Bester" : "Najlepszy",
          "Summe" : "Całkowity",
          "Gesamt" : "Całkowity",
        },
      },

      translationComparison : {
        DEU : {
        },
        ENG : {
          "Zwei Fahrer in einem Lauf" : "Two Drivers within a Heat",
          "Zwei Läufe eines Fahrers" : "Two Heats of a Driver",
        },
        FRA : {
          "Zwei Fahrer in einem Lauf" : "Deux Conducteurs dans une Course",
          "Zwei Läufe eines Fahrers" : "Deux Courses d'un Conducteur",
        },
        ITA : {
          "Zwei Fahrer in einem Lauf" : "Due Conduttori in una Gara",
          "Zwei Läufe eines Fahrers" : "Due Gare di un Conduttore",
        },
        CZE : {
          "Zwei Fahrer in einem Lauf" : "Dva Jezdci v jednom Běhu",
          "Zwei Läufe eines Fahrers" : "Dva Běhy jednoho Jezdca",
        },
        POL : {
          "Zwei Fahrer in einem Lauf" : "Dwa Kierowcy w jednym Biegu",
          "Zwei Läufe eines Fahrers" : "Dwa Biegi jednego Kierowcy",
        },
      },

      translationProgress : {
        DEU : {
        },
        ENG : {
          "Fortschritt" : "Progress",
        },
        FRA : {
          "Fortschritt" : "Progrès",
        },
        ITA : {
          "Fortschritt" : "Progressi",
        },
        CZE : {
          "Fortschritt" : "Pokrok",
        },
        POL : {
          "Fortschritt" : "Postęp",
        },
      },
    }
  },
  methods: {
    translateSpecial(text, map) {
      let match = /([\wäöüß]+)|[^\wäöüß]+/;
      //let parts = match.exec(text);
      let parts = XRegExp.match(text, match, 'all');
      //console.log(parts);
      let result = '';
      for (let part of parts)
        if (part[1] !== ' ')
          result = result + this.translate(part, map)
        else
          result = result + part;  
      return result;
    },

    translateCLSTitle(text) {
      return this.translateSpecial(text, this.translationClassificationTitles);
    },

    translateGroupTitle(text) {
      return this.translateSpecial(text, this.translationGroupTitles);
    },

    translateTableHead(text) {
      return this.translateSpecial(text, this.translationTableHeads);
    },

    translateProgress(text) {
      return this.translate(text, this.translationProgress);
    },

    translateComparison(text) {
      return this.translate(text, this.translationComparison);
    },
  }
}