export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
           "Credits" : "Tribut",
            "logoutMessage" : "Sie wurden erfolgreich abgemeldet!",
         },
         ENG : {
           "Sidebar an" : "show sidebar",
           "Impressum" : "Imprint",
           "Datenschutz" : "Privacy",
           "Rennen" : "Race",
           "Starterliste" : "List of Competitors",
           "Benutzer" : "User",
           "Anmelden" : "Login",
           "Registrieren" : "Register",
           "Abmelden" : "Logout",
           "Passwort ändern" : "Change Password",
           "Daten ändern" : "Change Data",
           "Tickereintrag erstellen" : "Create Ticker Entry",
           "Rennen anlegen" : "Create a Race",
           "Rennen auswählen" : "Select Race",
           "Ergebnislog anzeigen" : "Display Result Log",
           "Event-Einstellungen anzeigen" : "Display Event Settings",
           "Zeitmaschine anzeigen" : "Display Time Machine",
           "Veranstaltung aktualisieren" : "Refresh Event",
           'Rennen wurde aktualisiert' : "Race has been refreshed",
           "Ergebnisse aktualisieren" : "Refresh Results",
           "Cache auffrischen" : "Clear Cache",
           "Aktive Veranstaltungen laden" : "Load Active Events",
           "Auswertung neu starten" : "Restart Classification",
           "logoutMessage" : "You have been logged out successfully!"
         },
         FRA : {
           "Sidebar an" : "afficher sidebar",
           "Impressum" : "Empreinte",
           "Datenschutz" : "Politique de Confidentialité",
           "Credits" : "Hommage",
           "Rennen" : "Epreuve",
           "Starterliste" : "Liste des Concurrents",
           "Benutzer" : "Utilisateur",
           "Anmelden" : "Connecter",
           "Registrieren" : "S'inscrire",
           "Abmelden" : "Déconnecter",
           "Passwort ändern" : "Changer le Mot de Passe",
           "Daten ändern" : "Changer les Dates",
           "Tickereintrag erstellen" : "Créer une Entrée de Ticker",
           "Rennen anlegen" : "Lancer une Course",
           "Rennen auswählen" : "Sélectionner la Course",
           "Ergebnislog anzeigen" : "Montrer le Log des Resultats",
           "Event-Einstellungen anzeigen" : "Montrer le Configuration du Epreuve",
           "logoutMessage" : "Vous avez été déconnecté"
         },
         ITA : {
           "Sidebar an" : "mostra sidebar",
           "Impressum" : "Impronta",
           "Datenschutz" : "Politica Sulla Privacy",
           "Credits" : "Omaggio",
           "Rennen" : "Gara",
           "Starterliste" : "Elenco dei Concorrenti",
           "Benutzer" : "Utente",
           "Anmelden" : "Accedi",
           "Registrieren" : "Registrazione",
           "Abmelden" : "Logout",
           "Passwort ändern" : "Cambia Password",
           "Daten ändern" : "Cambia le Date",
           "Tickereintrag erstellen" : "Crea una Voce nel Ticker",
           "Rennen anlegen" : "Inizia una Gara",
           "Rennen auswählen" : "Seleziona Gara",
           "logoutMessage" : "Logout riuscito"
         },
         CZE : {
           "Sidebar an" : "zobrazit sidebar",
           "Impressum" : "Otisk",
           "Datenschutz" : "Ochrana Osobních Údajů",
           "Credits" : "Uznání",
           "Rennen" : "Závod",
           "Starterliste" : "Seznam Závodníků",
           "Benutzer" : "Uživatel",
           "Anmelden" : "Přihlášení",
           "Registrieren" : "Registrace",
           "Abmelden" : "Odhlášení",
           "Passwort ändern" : "Změňte Heslo",
           "Daten ändern" : "Změnit Data",
           "Tickereintrag erstellen" : "Vytvoření Záznamu v Tickeru",
           "Rennen anlegen" : "Začněte Závod",
           "Rennen auswählen" : "Vyberte Závody",
           "logoutMessage" : "Odhlášení úspěšné"
         },
         POL : {
           "Sidebar an" : "pokaż sidebar",
           "Impressum" : "Znak Firmowy Wydawcy",
           "Datenschutz" : "Polityka Prywatności",
           "Credits" : "Uznanie",
           "Rennen" : "Wyścig",
           "Starterliste" : "Lista Konkurentów",
           "Benutzer" : "Użytkownik",
           "Anmelden" : "Zaloguj",
           "Registrieren" : "Zarejestruj",
           "Abmelden" : "Wyloguj",
           "Passwort ändern" : "Zmień Hasło",
           "Daten ändern" : "Zmień Daty",
           "Tickereintrag erstellen" : "Utworzyć Pozycję w Tikerze",
           "Rennen anlegen" : "Rozpocznij Wyścig",
           "Rennen auswählen" : "Wybierz Wyścig",
           "logoutMessage" : "Wylogowano pomyślnie"
         },
      }
    }
  },
}