export default class NavigationHelper {
  static getCurrentStreamingLinks(streaming, now)
  {
    if (streaming && !Array.isArray(streaming))
      streaming = [streaming];

    let results = [];
    if (streaming) {
      for (let entry of streaming) {
        let from = new Date(Date.parse(entry.from));
        let to = new Date(Date.parse(entry.to));
        const fromOk = (!entry.from || now >= from);
        const toOk = (!entry.to || now <= to);
        const title = entry.title ?? entry._text ?? 'Stream';
        if (fromOk && toOk)
          results.push({ link : entry.link, title});
      }
    }

    return results;
  }
}